<template>
  <div>
    <v-dialog v-model="open" :max-width="maxWidth" @click:outside="$emit('cancel')">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>

        <v-card-text>
          <p class="mt-5 mb-0 fs-16 text-center">
            {{ message }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="$emit('cancel')">
            {{ nameCancel }}
          </v-btn>

          <v-btn color="green darken-1" text @click="$emit('accept')" :loading="loading">
            {{ nameAccept }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    message: String,
    open: Boolean,
    cancel: Function,
    accept: Function,
    maxWidth: {
      default: 400,
      type: Number,
    },
    nameCancel: {
      default: "No",
      type: String,
    },
    nameAccept: {
      default: "Si",
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
