<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" persistent max-width="900px">
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-form v-on:submit.prevent="editItem">
          <v-card-title class="mb-4">
            <span class="headline">Cobranza a cuenta</span>
          </v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col-md-12">
                <!--Comprobante a Cobrar-->
                <ComprobanteACobrar :isAssignVoucher="true" :item="item" :open="dialog" :validator="$validator" />
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('closeDialog')">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading">Editar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import { chargeService } from "@/libs/ws/chargeService";
import Vue from "vue";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import ComprobanteACobrar from "@/views/Charges/ChargeComponent/ComprobanteACobrar.vue";

export default {
  mixins: [ModalGenericMixin],
  components: { ComprobanteACobrar },
  props: {
    voucher: { type: Object, default: null },
    openFromVoucher: { type: Boolean, default: false },
    charge: { type: Object, default: null },
    dialog: Boolean,
    formBtn: String,
    closeDialog: Function,
    loadList: Function,
  },
  data() {
    return {
      show: false,
      formatUtils: formatUtils,
      total: 0,
      item: {
        id: 0,
        client: null,
        vouchers: [],
        cobranza_a_cuenta: 0,
        money_account: null,
        payment_method: null,
        cbte_fch: null,
        dolar_cotiz: null,
        fch_charge_efective: null,
        amount: 0,
        tributes: [],
        cheque: {
          emitter_name: null,
          bank: null,
          number: null,
        },
      },
      defaultItem: null,
      mountedChild: false,
    };
  },
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.$emit("closeDialog");
      }
    });
  },
  watch: {
    dialog(val) {
      this.$validator.reset();
      this.errors.clear();
      this.init();
    },
    item: {
      handler: function (newValue) {
        this.getTotal();
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    init() {
      if (this.charge != null) {
        this.item = this.charge;
      }
    },
    getTotal() {
      let total = 0;
      if (this.item.amount != null) {
        total = parseFloat(this.item.amount);
      }

      this.item.tributes.forEach((tribute) => {
        const amount = tribute.amount != undefined ? tribute.amount : 0;
        total += parseFloat(amount);
      });
      this.total = total;
    },
    customValidations() {
      var total_vouchers = 0.0;
      this.item.vouchers.forEach((voucher) => {
        total_vouchers += parseFloat(voucher.imp_total);
      });
      if (total_vouchers.toFixed(2) > this.total.toFixed(2)) {
        this.$toast.error("El total de los comprobantes a cobrar debe ser menor o igual al total de la cobranza", "Error");
        return false;
      }
      return true;
    },
    editItem(e) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          e.preventDefault();

          if (!this.customValidations()) {
            return;
          }

          this.loading = true;

          const data = {
            id: this.item.id,
            vouchers_arr: this.item.vouchers,
            client: this.item.client.id,
            payment_method: this.item.payment_method,
            cbte_fch: this.item.cbte_fch,
            dolar_cotiz: this.item.dolar_cotiz,
            amount: this.item.amount,
          };

          chargeService
            .update(data)
            .then((response) => {
              if (response.status) {
                this.$toast.success("La cobranza se actualizo correctamente", "OK");
                this.$emit("closeDialog");
                this.$emit("loadList");
                this.item = JSON.parse(JSON.stringify(this.defaultItem));
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.$toast.error(response.message, "Error");
                } else {
                  this.$toast.error(response.msg, "Error");
                }
              }
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
            })
            .finally(() => (this.loading = false));
        }
      });
    },
  },
};
</script>
