var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Title',{attrs:{"pages":[
      { icon: 'file-invoice', path: '/suppliers', page: 'PROVEEDORES' },
      { icon: 'file-invoice', page: _vm.page_title.toUpperCase() },
    ]}}),_c('v-container',{attrs:{"fluid":""}},[(_vm.list_access)?_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"col-md-8 pb-0 pl-0"},[_c('v-chip',{attrs:{"outlined":"","color":"primary"}},[_vm._v("Comprobantes pendiente de pago: "),_c('span',[_vm._v(_vm._s(_vm.formatUtils.formatPrice(_vm.total)))])]),_c('v-chip',{staticClass:"ml-2",attrs:{"outlined":"","color":"blue-grey"}},[_vm._v(" Pagos a cuenta: "),_c('span',{},[_vm._v(_vm._s(_vm.formatUtils.formatPrice(_vm.total_pago_a_cuenta)))])])],1),_c('div',{staticClass:"col-md-4 pb-0"},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-col',{attrs:{"md":"12"}},[_c('v-btn',{attrs:{"color":"blue-grey","small":"","dark":"","loading":_vm.dialog_export},on:{"click":function($event){return _vm.exportFile()}}},[_vm._v(" Exportar "),_c('font-awesome-icon',{staticClass:"ml-2",attrs:{"icon":"download"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando...","options":{
          descending: true,
          itemsPerPage: 15,
        },"footer-props":{
          itemsPerPageOptions: [5, 10, 15, 50, -1],
        }},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.cbte_fch))]),_c('td',[_vm._v(_vm._s(props.item.voucher_number_label))]),_c('td',[_vm._v(_vm._s(props.item.receiver_name))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatUtils.formatPrice(props.item.total_factura)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatUtils.formatPrice(props.item.total_pagado_de_factura)))]),_c('td',{staticClass:"text-right text-danger"},[_vm._v(_vm._s(_vm.formatUtils.formatPrice(props.item.total_pendiente)))]),_c('td',{domProps:{"innerHTML":_vm._s(_vm.getStatus(props.item.expiration))}})])]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s("Busqueda sin resultados - " + _vm.search)+" ")])]},proxy:true}],null,false,2455317441)})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }