<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Datos generales</h6>
      <p class="text-body-2 mb-0">Ingrese el emisor de la factura, el receptor y el tipo de comprobante</p>

      <v-row class="mt-0">
        <v-col md="4" class="mt-2">
          <v-combobox
            v-model="sale_order.emitter"
            data-vv-as="emisor"
            name="emitter"
            item-text="name"
            item-value="id"
            :items="emitters"
            label="Emisor*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            :loading="loading_combos['emitters']"
            v-on:change="
              emitterChangeCombo($event);
            "
            :disabled="order_to_cancel != null || emitter != null || sale_order.id > 0"
          ></v-combobox>
          <span class="red--text">{{ errors.first("emitter") }}</span>
        </v-col>

        <v-col md="4" class="mt-2" >
          <v-combobox
            v-model="sale_order.receiver"
            data-vv-as="receptor"
            name="receiver"
            :item-text="receiver_name"
            item-value="id"
            :items="receivers"
            label="Receptor*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            :loading="loading_combos['receivers']"
            :disabled="order_to_cancel != null || receiver != null || sale_order.id > 0"
          ></v-combobox>
          <span class="red--text">{{ errors.first("receiver") }}</span>
        </v-col>

        <v-col md="4" class="mt-2">
          <v-text-field autocomplete="off"
            data-vv-as="orden de compra" label="Referencia externa" name="external_reference"
            v-model="sale_order.external_reference"  :disabled="order_to_cancel != null || sale_order.id > 0">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col md="3">
          <v-text-field
            autocomplete="off"
            data-vv-as="numero de orden"
            label="Numero Orden*"
            name="sale_order_number"
            v-validate="'integer|required'"
            v-model="sale_order.sale_order_number"
            :loading="loading_sale_order_number"
            :disabled="sale_order.sale_order_number == null || sale_order.id > 0"
            >
          </v-text-field>
          <span class="red--text">{{ errors.first("sale_order_number") }}</span>
        </v-col>
        <v-col md="3">
          <!-- No se peuden emitir comproabtes de venta en dolares aun -->
          <!-- monedas.length == 0 || order_to_cancel != null -->
          <v-combobox
            :loading="loading_combos['monedas']"
            :disabled="true"
            v-model="sale_order.moneda"
            data-vv-as="moneda"
            name="moneda"
            item-text="Desc"
            item-value="Id"
            :items="monedas"
            label="Moneda*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
          ></v-combobox>
          <span class="red--text">{{ errors.first("moneda") }}</span>
        </v-col>

        <v-col md="3">
          <v-text-field
            v-if="sale_order.moneda != null && sale_order.moneda.Id !== 'PES'"
            autocomplete="off"
            data-vv-as="cotizacion de la moneda"
            label="Cotización de la moneda*"
            name="mon_cotiz"
            v-validate="'decimal|required'"
            v-model="sale_order.mon_cotiz"
            :disabled="order_to_cancel != null"
          >
          </v-text-field>
          <span class="red--text">{{ errors.first("mon_cotiz") }}</span>
        </v-col>
       
        <v-col md="3">
          <v-text-field v-if="sale_order.purchase_order != null" autocomplete="off"
            data-vv-as="orden de compra" label="Orden de Compra" name="purchase_order"
            v-model="sale_order.purchase_order.purchase_order_number"
            :disabled="true">
          </v-text-field>
          <span class="red--text">{{ errors.first("purchase_order") }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { emmiterService } from "@/libs/ws/emmiterService";
import { receiversService } from "@/libs/ws/receiversService";
import { saleOrderService } from "../../../libs/ws/saleOrderService";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  props: {
    open: Boolean,
    sale_order: Object,
    loading_combos: Object,
    validator: Object,
    order_to_cancel: Object,
    settings: Object,
    receiver: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Object,
    },
    emitter: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Object,
    },
  },
  data: () => ({
    emitters: [],
    receivers: [],
    cbte_tipos: [],
    ptosvtas: [],
    conceptos: [],
    monedas: [],
    loading_sale_order_number:false
  }),
  watch: {
    open: function (val) {
      if (val) {
        this.errors.clear();
        this.$validator.reset();
        this.loadComboData();
      }
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    receiver_name: (item) => (item == null ? "" : item.name + " (" + item.client.name + ")"),
    loadComboData() {
      this.loading_combos["emitters"] = true;
      emmiterService
        .filter({ enabled: true })
        .then((response) => {
          this.emitters = response.result;
          if (this.emitter != null && this.emitter.id > 0) {
            this.sale_order.emitter = this.emitter;
            this.emitterChangeCombo();
          } else if (response.result.length == 1) {
            this.sale_order.emitter = response.result[0];
            this.emitterChangeCombo();
          }
        })
        .finally(() => {
          this.loading_combos["emitters"] = false;
        });

      this.loading_combos["receivers"] = true;

      receiversService
        .filter({})
        .then((response) => {
          this.receivers = response.result;
          if (this.receiver != null && this.receiver.id > 0) {
            this.sale_order.receiver = this.receiver;
          }
        })
        .finally(() => {
          this.loading_combos["receivers"] = false;
        });

      if (this.sale_order.emitter != null && this.sale_order.emitter.id > 0) this.emitterChangeCombo();
    },
    emitterChangeCombo(event) {
      this.dialog_loading = true;
      if (this.sale_order.emitter != null && this.sale_order.emitter.id) {
        this.getNextNumSaleOrder();

        this.loading_combos["monedas"] = true;
        emmiterService.monedas(this.sale_order.emitter.id).then((response) => {
          if (response.status) {
            this.monedas = response.result;
            this.loading_combos["monedas"] = false;
            this.sale_order.moneda = response.result[0];
            if (this.order_to_cancel != null) this.selectComboId(this.monedas, this.order_to_cancel.moneda, "Id");
          }
        });
      }
    },
   
    getNextNumSaleOrder() {
      if (this.sale_order.emitter != null && this.sale_order.emitter.id > 0 && !this.isViewModal && this.sale_order.id == 0) {
        if (this.loading_sale_order_number) return;

        this.loading_sale_order_number = true;
        saleOrderService
          .get_next_number(this.sale_order.emitter.id)
          .then((response) => {
            if (response.status) {
              this.sale_order.sale_order_number = response.result;
            }
          })
          .finally(() => {
            this.loading_sale_order_number = false;
          });
      }
    },
    selectComboId(combo, id, property_id = "id") {
      if (combo != null && combo.length > 0) {
        for (let i = 0; i < combo.length; i++) {
          if (combo[i][property_id] == id) {
            return combo[i];
          }
        }
      }
      return null;
    },
  },
};
</script>
