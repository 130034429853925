<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" persistent max-width="1200px">
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-form v-on:submit.prevent="createItem">
          <v-card-title class="mb-4">
            <span class="headline">Pago</span>
          </v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col-md-12">
                <!--Datos Generales-->
                <DatosGenerales :item="item" :isViewModal="isViewModal()" :open="dialog" :openFromSpent="openFromSpent" :validator="$validator" />

                <!--Comprobante a Cobrar-->
                <ComprobanteAPagar :item="item" :isViewModal="isViewModal()" :open="dialog" :spent="this.spent" :validator="$validator" :openFromCreateGasto="openFromCreateGasto"/>

                <!--Forma de Cobro-->
                <FormasDePago :item="item" :isViewModal="isViewModal()" :open="dialog" :validator="$validator" />

                <!--Retenciones-->
                <Retenciones :item="item" :isViewModal="isViewModal()" :open="dialog" :validator="$validator" />

                <Observation :item="item" :isViewModal="isViewModal()" subtitle="Ingrese una observación"/>

                <v-row>
                  <!--Info Extra-->
                  <InfoExtra :dialog="dialog" :item="item" :open="dialog" :isViewModal="isViewModal()" :validator="$validator"/>
                  <!--Totales-->
                  <Totales :total="total" :open="dialog" />
                </v-row>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close()">Cerrar</v-btn>
            <v-btn color="success" v-if="!isViewModal()" text type="submit" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import { paymentSpentService } from "@/libs/ws/paymentSpentService";

import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";

import DatosGenerales from "@/views/Spents/PaymentComponent/DatosGenerales.vue";
import ComprobanteAPagar from "@/views/Spents/PaymentComponent/ComprobanteAPagar.vue";
import FormasDePago from "@/views/Spents/PaymentComponent/FormasDePago.vue";
import Retenciones from "@/views/Spents/PaymentComponent/Retenciones.vue";
import InfoExtra from "@/components/Comprobantes/InfoExtra.vue";
import Totales from "@/views/Spents/PaymentComponent/Totales.vue";
import Observation from "@/components/Comprobantes/Observation.vue";

export default {
  mixins: [ModalGenericMixin],
  components: { DatosGenerales, ComprobanteAPagar, FormasDePago, Retenciones, InfoExtra, Totales,Observation },
  props: {
    spent: { type: Object, default: null },
    openFromSpent: { type: Boolean, default: false },
    openFromCreateGasto: { type: Boolean, default: false },
    spentPayment: { type: Object, default: null },
    dialog: Boolean,
    button_name: String,
    formBtn: String,
    closeDialog: Function,
    loadList: Function,
  },
  data() {
    return {
      show: false,
      formatUtils: formatUtils,
      total: 0,
      defaultItem: null,
      item: {
        id: 0,
        supplier: null,
        spents: [],
        payment_method: null,
        dolar_cotiz: null,
        fch_payment_efective: null,
        tributes: [],
        spent_payment_methods: [],
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        observation: null,
      },
    };
  },
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  watch: {
    dialog(val) {
      this.$validator.reset();
      this.errors.clear();
      this.init();
      //cuando le envio el gasto por props desde gastos
      if (val && this.spent != null) {
        this.item.supplier = this.spent.supplier;
      }
    },
    item: {
      handler: function (newValue) {
        this.getTotal();
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
    this.init();
  },
  methods: {
    close() {
      this.item = JSON.parse(JSON.stringify(this.defaultItem));
      this.$emit("closeDialog");
    },
    init() {
      if (this.spentPayment != null) {
        this.item = this.spentPayment;
      }
    },
    getTotal() {
      let total = 0;

      this.item.spent_payment_methods.forEach((c_payment_methos) => {
        const amount = c_payment_methos.amount != undefined ? c_payment_methos.amount : 0;
        total += parseFloat(amount);
      });

      this.item.tributes.forEach((tribute) => {
        const amount = tribute.amount != undefined ? tribute.amount : 0;
        total += parseFloat(amount);
      });
      this.total = total;
    },
    customValidations() {
      var total_spents = 0.0;
      this.item.spents.forEach((spent) => {
        total_spents += parseFloat(spent.imp_total);
      });
      if (this.total.toFixed(2) != total_spents.toFixed(2)) {
        this.$toast.error("El total de los comprobantes a pagar debe ser igual al total del pago", "Error");
        return false;
      }
      return true;
    },
    provide() {
      return {
        $validator: this.$validator,
      };
    },
    createItem(e) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          e.preventDefault();

          if (!this.customValidations()) {
            return;
          }

          this.loading = true;

          const data = {
            spents_arr: this.item.spents,
            supplier: this.item.supplier.id,
            payment_method: this.item.payment_method,
            cbte_fch: this.item.cbte_fch,
            dolar_cotiz: this.item.dolar_cotiz,
            amount: this.total,
            fch_payment_efective: this.item.fch_payment_efective,
            tributes_arr: this.item.tributes,
            cheque: this.item.cheque,
            spent_payment_methods_arr: this.item.spent_payment_methods,
            observation: this.item.observation
          };

          paymentSpentService
            .create(data)
            .then((response) => {
              if (response.status && response.result.id > 0) {
                this.$toast.success("El pago se creo correctamente", "OK");
                this.close();
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.$toast.error(response.message, "Error");
                } else {
                  this.$toast.error(response.msg, "Error");
                }
              }
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
            })
            .finally(() => (this.loading = false));
        }
      });
    },
    isViewModal() {
      return this.formBtn == "";
    },
  },
};
</script>
