<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-btn color="primary" class="mb-5" dark @click="dialog = true">Crear Comprobante</v-btn>

      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="supplier_selected"
                    autocomplete="off"
                    data-vv-as="supplier_selected"
                    name="supplier_selected"
                    item-text="name"
                    item-value="id"
                    :items="suppliers"
                    label="Proveedor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="receiver_selected"
                    autocomplete="off"
                    data-vv-as="receiver_selected"
                    name="receiver_selected"
                    item-text="name"
                    item-value="id"
                    :items="receivers"
                    label="Receptor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <v-text-field clearable v-model="voucher_number_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Comprobante"></v-text-field>
                </div>

                <div class="col-md-6"></div>

                <div class="col-md-3" style="display: flex; justify-content: end">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="blue-grey mr-2" dark v-bind="attrs" v-on="on"> Exportar <font-awesome-icon class="ml-2" icon="caret-down" /></v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="exportToExcel">
                        <v-list-item-title>Exportar listado</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="exportLibroIVA">
                        <v-list-item-title>Exportar libro IVA</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <h5 v-if="list_access" style="padding: 10px; margin-top: 0px; background: #222d32; color: #fff; font-weight: bold">
        <v-chip class="mx-1 my-0" color="white" outlined pill v-if="list_access"> Total S/IVA: ${{ new Intl.NumberFormat("en-EN").format(total) }} </v-chip>

        <v-chip class="mx-1 my-0" color="white" outlined pill v-if="list_access"> Total IVA: ${{ new Intl.NumberFormat("en-EN").format(parseFloat(total_iva).toFixed(2)) }} </v-chip>
      </h5>
      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.cbte_fch }}</td>
              <td>{{ props.item.cbte_tipo_name }}</td>
              <td>{{ props.item.spent_number_label }}</td>
              <td>{{ props.item.supplier.name }}</td>
              <td>{{ props.item.receiver.name }}</td>
              <td>{{ props.item.mon_name }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_ars) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_usd) }}</td>

              <td class="text-center px-0">
                <v-tooltip v-if="showCbteAsociado(props.item)" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewcbtesAsoc(props.item)"> info </v-icon>
                  </template>
                  <span>Comprobantes asociados</span>
                </v-tooltip>
                <p v-else>-</p>
              </td>

              <td v-if="props.item.paid" class="text-center"><font-awesome-icon icon="check" /></td>
              <td v-else class="text-right">
                <span :class="getPendingToChargeClass(props.item)">{{ !props.item.is_credit_note && props.item.state.id != 2 ? "-" + formatUtils.formatPrice(props.item.pending_to_paid) : "" }}</span>
              </td>

              <td class="text-right px-0 pr-3" style="min-width: 150px">
                <v-tooltip bottom v-if="props.item.is_invoice || props.item.is_debit_note">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="orange" class="mr-2" @click="viewSpentBalances(props.item)"> description </v-icon>
                  </template>
                  <span>Ver imputacion de saldos</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.state.id != 2">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!props.item.is_credit_note && props.item.is_invoice && props.item.state.id != 2 && !props.item.paid">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="green" class="mr-2" @click="openPayment(props.item)"> payments </v-icon>
                  </template>
                  <span>Pagar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <!-- <v-tooltip bottom v-if="props.item.is_invoice && props.item.state.id == 1">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" class="mr-2" @click="cancelInvoice(props.item)"> close </v-icon>
                  </template>
                  <span>Anular</span>
                </v-tooltip> -->

                <v-tooltip bottom v-if="props.item.imp_credit_accumulated == 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogGenerarSpent
        :invoice_to_cancel="invoice_to_cancel"
        :item="mainItem"
        :open="dialog"
        @cancel="cancelModal"
        @closeDialog="close"
        @updateDialog="filter"
        :formBtn="formBtn"
        :title="formBtn + ' Gasto'"
        @reload="filter"
        :isViewModal="isViewModal() ? true : false"
        @openModalPayment="openModalPayment"
      />

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" @reload="filter" />

      <DialogBalances
        title="Imputacion de saldos"
        :show="dialog_view_balances"
        :spent_id="spent_id"
        :pending_to_paid="pending_to_paid"
        :spent_number_label="spent_number_label"
        @closeDialog="closeDialogBalances"
      />

      <ModalSpentPayment
        v-if="update_access"
        :spent="spent_to_pay"
        :openFromSpent="true"
        :openFromCreateGasto="open_from_create_gasto"
        :formBtn="formBtn"
        :dialog="dialog_paid"
        @closeDialog="closPaymentDialog"
        @updateItem="
          (newItem) => {
            spent_to_pay = newItem;
          }
        "
      />

      <CbtesAsocModal
        :open="dialog_cbtes_asoc"
        :item="voucher_selected"
        :can_disassociate="update_access"
        @cancel="
          dialog_cbtes_asoc = false;
          voucher_selected = null;
        "
        :service="service"
        @reload="filter"
        :isSpent="true"
      />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { spentService } from "@/libs/ws/spentService";
import { vouchersService } from "@/libs/ws/vouchersService";
import { emmiterService } from "@/libs/ws/emmiterService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import DialogGenerarSpent from "./DialogGenerarSpent.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import ModalSpentPayment from "@/views/Spents/ModalSpentPayment.vue";
import CbtesAsocModal from "@/components/Comprobantes/CbtesAsocModal.vue";
import DialogBalances from "./DialogBalances.vue";

export default {
  components: { Title, DialogGenerarSpent, DialogDelete, ModalSpentPayment, CbtesAsocModal, DialogBalances },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      formatUtils: formatUtils,
      parent: this,
      service: spentService,
      page_title: "Comprobantes de Gastos",
      modal_title: "Gasto",
      mainItem: {
        id: null,
        voucher_number: null,
        cost_center: null,
        dolar_cotiz: null,
        supplier: null,
        receiver: null,
        cbte_tipo: null,
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        cbte_fch_serv_desde: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        cbte_fch_serv_hasta: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        fch_vto_pago: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        concepto: null,
        moneda: null,
        mon_cotiz: 1,
        items_custom: [],
        tributes: [],
        cbtes_asoc: null,
      },
      dialog_delete: false,
      dialog_cbtes_asoc: false,
      voucher_selected: null,
      receiver_selected: null,
      cost_center_selected: null,
      loading_dialog: false,
      item_dialog: null,
      defaultItem: null,
      open_from_create_gasto: false,
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Comprobante", align: "left", sortable: true, value: "cbte_tipo_name" },
        { text: "Nº Comprobante", align: "left", sortable: true, value: "spent_number_label" },
        { text: "Proveedor", align: "left", sortable: true, value: "supplier.name" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
        { text: "Total ARS", align: "center", sortable: true, value: "imp_total" },
        { text: "Total USD", align: "center", sortable: true },
        { text: "Cbtes Asoc.", align: "left", sortable: true },
        { text: "Pagada", align: "center", sortable: true },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      suppliers: [],
      receivers: [],
      sub_costs_centers: [],
      costs_centers: [],
      desde_selected_open: false,
      hasta_selected_open: false,
      supplier_selected: null,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      voucher_number_selected: null,
      spent_to_pay: null,
      dialog_paid: false,
      invoice_to_cancel: null,
      dialog: false,
      total: 0.0,
      total_iva: 0.0,
      spent_id: null,
      pending_to_paid: null,
      spent_number_label: null,
      dialog_view_balances: false,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service, this.getFilterParams());
  },
  methods: {
    viewSpentBalances(item) {
      this.spent_id = item.id;
      this.pending_to_paid = item.pending_to_paid;
      this.spent_number_label = item.spent_number_label;
      this.dialog_view_balances = true;
    },
    closeDialogBalances() {
      this.spent_id = null;
      this.pending_to_paid = null;
      this.spent_number_label = null;
      this.dialog_view_balances = false;
    },
    isViewModal() {
      return this.formBtn == "";
    },
    showCbteAsociado(invoice) {
      return !vouchersService.VoucherList().includes(invoice.cbte_tipo.Id);
    },
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        supplier: this.supplier_selected != null ? this.supplier_selected.id : null,
        receiver: this.receiver_selected != null ? this.receiver_selected.id : null,
        cost_center: this.cost_center_selected != null ? this.cost_center_selected.id : null,
        voucher_number: this.voucher_number_selected,
      };
      return param;
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      service
        .filter(param)
        .then((response) => {
          this.list = response.result;
          this.loading = false;
          this.getTotal();
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadComboData() {
      emmiterService
        .filter()
        .then((response) => {
          this.receivers = response.result;
        })
        .catch((e) => {
          console.log("emmiterService.filter error");
          console.log(e);
        });

      generalService.setSection(Constant.SECTION_SUPPLIERS);
      generalService
        .filter()
        .then((response) => {
          this.suppliers = response.result;
        })
        .catch((e) => {
          console.log("suppliers.filter error");
          console.log(e);
        });

      generalService.setSection(Constant.SECTION_SUB_COSTS_CENTERS);
      generalService
        .filter()
        .then((response) => {
          this.sub_costs_centers = response.result;
        })
        .catch((e) => {
          console.log("sub_costs_centers.filter error");
          console.log(e);
        });

      generalService.setSection(Constant.SECTION_COSTS_CENTERS);
      generalService
        .filter()
        .then((response) => {
          this.costs_centers = response.result;
        })
        .catch((e) => {
          console.log("costs_centers.filter error");
          console.log(e);
        });
    },
    deleteItemConfirm() {
      this.loading = true;
      spentService
        .remove({ id: this.deleteId })
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.loadList(this.service, this.getFilterParams());
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("spentService.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        })
        .finally(() => {
          this.loading = false;
        });
      this.dialog_delete = false;
    },
    cancelModal() {
      this.dialog = false;
      this.invoice_to_cancel = null;
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    openPayment(item) {
      this.spent_to_pay = item;
      this.dialog_paid = true;
    },
    closPaymentDialog() {
      this.spent_to_pay = null;
      this.dialog_paid = false;
      this.open_from_create_gasto = false;

      this.loadList(this.service, this.getFilterParams());
    },
    getPendingToChargeClass(item) {
      const state_canceled = 2;
      return !item.is_credit_note && item.state.id != state_canceled ? "text-danger text-nowrap" : "";
    },
    cancelInvoice(item) {
      this.invoice_to_cancel = item;
      this.dialog = true;
    },
    viewcbtesAsoc(item) {
      this.voucher_selected = item;
      this.dialog_cbtes_asoc = true;
    },
    getTotal() {
      this.total = 0;
      this.total_iva = 0;

      if (this.list.length > 0) {
        //si es una nota de credito restar, separar en v chip total - imp iva - imp trib otro chip imp iva

        this.list.forEach((element) => {
          let total_neto_item = 0.0;
          if (element.is_credit_note) {
            this.total_iva = (parseFloat(this.total_iva) - parseFloat(element.imp_iva)).toFixed(2);
            total_neto_item = parseFloat(element.imp_total) + parseFloat(element.imp_iva) + parseFloat(element.imp_trib); //Porque la NC total viene como negativo
          } else {
            this.total_iva = (parseFloat(this.total_iva) + parseFloat(element.imp_iva)).toFixed(2);
            total_neto_item = parseFloat(element.imp_total) - parseFloat(element.imp_iva) - parseFloat(element.imp_trib);
          }
          //nota de credito ya viene en negativo.
          this.total = (parseFloat(this.total) + total_neto_item).toFixed(2);
        });
      }
    },
    exportToExcel() {
      if (this.loading) return;
      this.loading = true;
      let param = this.getFilterParams();

      spentService
        .export_filter(param)
        .then((response) => {
          if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
            this.$toast.error(response.msg, "Error");
            this.loading = false;
            return;
          }

          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "Gastos.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          link.click();
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.export_filter error");
          console.log(e);
          this.loading = false;
        });
    },
    exportLibroIVA() {
      if (this.loading) return;
      this.loading = true;
      let param = this.getFilterParams();

      //Validate mandatory filters: emitter, from, to
      if (!param.receiver || !param.from || !param.to) {
        this.$toast.error("Debe seleccionar un receptor, fecha desde y fecha hasta para exportar el libro de IVA.", "Error");
        this.loading = false;
        return;
      }

      spentService
        .export_libro_iva_filter(param)
        .then((response) => {
          if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
            this.$toast.error(response.msg, "Error");
            this.loading = false;
            return;
          }

          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "libro_iva_compra.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          link.click();
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.export_libro_iva_filter error");
          console.log(e);
          this.loading = false;
        });
    },
    openModalPayment(item) {
      this.errors.clear();
      this.spent_to_pay = JSON.parse(JSON.stringify(item));
      this.open_from_create_gasto = true;
      this.dialog_paid = true;
    },
  },
};
</script>
