<template>
  <v-card v-if="showCbteAsociado()" elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Comprobante asociado</h6>
      <p class="text-body-2">Ingrese los datos del comprobante asociado al que intenta generar</p>

      <v-row>
        <v-col md="5">
          <v-combobox
            v-model="comprobante_asociado.cbte_tipo"
            data-vv-as="Tipo de comprobante asociado"
            name="cbte_tipo_asoc"
            item-text="Desc"
            item-value="Id"
            :items="cbte_tipos_invoice"
            label="Tipo de comprobante"
            autocomplete="off"
            class="overflow-auto"
            filled
            clearable
            v-validate="'combobox'"
            @change="$emit('searchCbteAsociado')"
            :disabled="invoice_to_cancel != null"
          ></v-combobox>
          <span class="red--text">{{ errors.first("cbte_tipo_asoc") }}</span>
        </v-col>

        <v-col md="6" class="mt-2">
          <v-text-field
            autocomplete="off"
            data-vv-as="Nº de factura"
            label="Nº de factura"
            name="num_voucher"
            v-model="comprobante_asociado.voucher_number"
            @blur="validateAndAutocomplete"
            :disabled="invoice_to_cancel != null"
          >
            <v-icon v-if="invoice.cbte_asoc != null" slot="append" color="green"> mdi-check </v-icon>
            <v-icon v-if="comprobante_asociado.voucher_number != '' && comprobante_asociado.voucher_numbe != null && invoice.cbte_asoc == '' && !loading_voucher_asociado" slot="append" color="red">
              mdi-close
            </v-icon>
          </v-text-field>
          <span class="red--text">{{ errors.first("num_voucher") }}</span>
        </v-col>
        <v-col v-if="showMessageNotFound()" md="12">
          <div>
            <span style="color: red">No se encontro el comprobante ingresado</span>
          </div>
        </v-col>
        <v-col v-if="loading_voucher_asociado" md="12">
          <div>
            <span color="blue-grey">Buscando comprobante...</span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import { vouchersService } from "@/libs/ws/vouchersService";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  props: {
    invoice: Object,
    invoice_to_cancel: Object,
    loading_combos: Object,
    comprobante_asociado: Object,
    loading_voucher_asociado: Boolean,
    error_search_cbte_asociado: Boolean,
    searchCbteAsociado: Function,
    validator: Object,
  },
  data: () => ({
    cbte_tipos_invoice: [],
    supplier_selected: null,
  }),
  watch: {
    invoice: {
      handler: function (newValue) {
        if (newValue == null) {
          return;
        }
        if ((this.supplier_selected == null && newValue.supplier != null) || (newValue.supplier != null && this.supplier_selected.id != newValue.supplier.id)) {
          this.supplier_selected = newValue.supplier;
          this.getCbtesTipos();
          //al cambiar el emisor se cambia el tipo de comprobante de datos generales reseteo cbe asociado
          //solo hace esto si no venis con un comprobate a cancelar desde facturacion
          if (this.invoice_to_cancel == null && this.invoice.cbte_asoc == null) {
            this.comprobante_asociado.cbte_tipo = null;
            this.comprobante_asociado.voucher_number = null;
            this.errors.clear();
            this.validator.reset();
          }
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    validateAndAutocomplete() {
      if (this.comprobante_asociado.voucher_number == null) {
        return;
      }

      const voucherNumber = this.comprobante_asociado.voucher_number;

      if (voucherNumber.length > 20) {
        this.comprobante_asociado.voucher_number = voucherNumber.slice(0, 20);
      }

      const afipRegex = /^\d{1,5}-\d{1,8}$/;
      if (afipRegex.test(voucherNumber)) {
        const parts = voucherNumber.split("-");
        if (parts.length === 2) {
          const pointOfSale = parts[0].padStart(5, "0");
          const invoiceNumber = parts[1].padStart(8, "0");
          this.comprobante_asociado.voucher_number = `${pointOfSale}-${invoiceNumber}`;
        }
      }

      this.$emit("searchCbteAsociado");
    },
    getCbtesTipos() {
      if (this.invoice.supplier != null && this.invoice.supplier.id > 0) {
        this.loading_combos["cbte_tipos_invoice"] = true;
        //Se Pide para saber si el comprobante seleccionado a emitir requiere comprobantes asociados (No es factura)
        billingGeneralService.cbteTiposInvoice(0).then((response) => {
          if (response.status) {
            this.cbte_tipos_invoice = response.result;
            this.loading_combos["cbte_tipos_invoice"] = false;

            /*
            if (this.invoice_to_cancel != null) {
              this.cbte_tipo_asoc = this.$emit("selectComboId", this.cbte_tipos_invoice, this.invoice_to_cancel.cbte_tipo, "Id");
            }
            */
          }
        });
      }
    },
    showCbteAsociado() {
      if (this.invoice == null || (this.invoice != null && this.invoice.cbte_tipo == null)) {
        return false;
      }
      return !vouchersService.VoucherList().includes(this.invoice.cbte_tipo.Id);
    },
    showMessageNotFound() {
      return !this.loading_voucher_asociado && this.error_search_cbte_asociado;
    },
  },
};
</script>
