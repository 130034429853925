<template>
  <v-dialog v-bind:value="dialog" @click:outside="close" fullscreen>
    <v-card>
      <v-card-title>
        <span class="headline">{{ modal_title }}</span>
      </v-card-title>

      <v-card-text>
        <v-btn small color="blue-grey" @click="addRow" class="mt-4 text-white">
          <v-icon big color="green"> add </v-icon>
          <span> Agregar Movimiento</span>
        </v-btn>

        <v-data-table :headers="headers" :items="movements" class="mt-4">
          <template v-slot:item="props">
            <tr>
              <td>
                <DatePickerEsp :initToday="true" :name="'date_' + props.index" :v-model="props.item.date" @setDate="(date) => (props.item.date = date)" label="Fecha" :isRequired="true" />
              </td>
              <td>
                <v-combobox
                  data-vv-as="Cuenta monetaria"
                  :name="'money_account_' + props.index"
                  v-model="props.item.money_account"
                  :items="money_accounts"
                  item-text="name"
                  item-value="id"
                  label="Cuenta monetaria*"
                  clearable
                  v-validate="'required|combobox_required'"
                />
                <span class="red--text">{{ errors.first("money_account_" + props.index) }}</span>
              </td>
              <td>
                <v-combobox
                  data-vv-as="Sub centro de costo"
                  :name="'sub_cost_center_' + props.index"
                  v-model="props.item.sub_cost_center"
                  :items="sub_costs_centers"
                  item-text="label"
                  item-value="id"
                  label="Sub Centro de Costos"
                  clearable
                  v-validate="'combobox_required'"
                />
                <span class="red--text">{{ errors.first("sub_cost_center_" + props.index) }}</span>
              </td>
              <td>
                <v-text-field :name="'concept_' + props.index" type="string" v-model="props.item.concept" label="Concepto" />
              </td>
              <td>
                <v-text-field :name="'reference_' + props.index" type="string" v-model="props.item.reference" label="Referencia" />
              </td>
              <td>
                <v-text-field data-vv-as="Importe" :name="'amount_debe_' + props.index" type="number" v-model="props.item.amount_debe" label="Importe" v-validate="'required|decimal'" />
                <span class="red--text">{{ errors.first("amount_debe_" + props.index) }}</span>
              </td>

              <td>
                <v-text-field data-vv-as="Importe" :name="'amount_haber_' + props.index" type="number" v-model="props.item.amount_haber" label="Importe" v-validate="'required|decimal'" />
                <span class="red--text">{{ errors.first("amount_haber_" + props.index) }}</span>
              </td>

              <td class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" @click="removeDetail(props.item)"> indeterminate_check_box </v-icon>
                  </template>
                  <span>Remover</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions style="position: absolute; bottom: 0; right: 0; left: 0; background: #fafafa">
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="close">Cerrar</v-btn>
        <v-btn color="success" text @click="createMovements" :loading="loading">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Constant from "@/constants/sections";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import { generalService } from "@/libs/ws/generalService";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";

export default {
  mixins: [ModalGenericMixin],
  components: { DatePickerEsp },
  props: {
    dialog: Boolean,
    modal_title: String,
    service: Object,
    updateDialog: Function,
    loadList: Function,
  },
  data() {
    return {
      movements: [],
      money_accounts: [],
      sub_costs_centers: [],
      loading: false,
      headers: [
        { text: "Fecha", value: "date" },
        { text: "Cuenta Monetaria", value: "money_account" },
        { text: "Sub Centro de Costos", value: "sub_cost_center" },
        { text: "Concepto", value: "concept" },
        { text: "Referencia", value: "reference" },
        { text: "Debe", value: "amount_debe" },
        { text: "Haber", value: "amount_haber" },
        { text: "Accion", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.loadComboData();
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.movements = [];
        this.addRow();
      }
    },
  },
  computed: {},
  methods: {
    close() {
      this.$emit("updateDialog", false);
      this.movements = [];
    },
    addRow() {
      this.movements.push({
        date: new Date(),
        money_account: null,
        sub_cost_center: null,
        movement_type: null,
        concept: null,
        reference: null,
        action_type: "M",
        amount_debe: 0,
        amount_haber: 0,
      });
    },
    createMovements(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          let hasError = false;
          let i = 1;
          this.movements.forEach((movement) => {
            if (movement.amount_haber === 0 && movement.amount_debe > 0) {
              movement.movement_type = "D";
            } else if (movement.amount_debe === 0 && movement.amount_haber > 0) {
              movement.movement_type = "H";
            }

            if (movement.movement_type == null) {
              if (movement.amount_debe > 0 && movement.amount_haber > 0) {
                this.$toast.error("Fila " + i + ": El debe y el haber no pueden ser ambos mayor a cero.");
                hasError = true;
                return;
              }
              this.$toast.error("Fila " + i + ": El movimiento presenta un error");
              hasError = true;
              return;
            }

            i++;
          });

          if (hasError) return;

          this.loading = true;

          this.service
            .createMasive(this.movements)
            .then((response) => {
              if (response.status) {
                this.$toast.success("Movimientos guardados correctamente", "OK");
                this.close();
                this.$emit("loadList");
              } else {
                this.$toast.error(response.msg || "Error al guardar", "Error");
              }
            })
            .catch(() => this.$toast.error("Error en la solicitud", "Error"))
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$toast.error("Existen campos que no son validos.");
        }
      });
    },
    removeDetail(item) {
      const index_item = this.movements.indexOf(item);
      if (index_item > -1) this.movements.splice(index_item, 1);
    },

    loadComboData() {
      generalService.setSection(Constant.SECTION_SUB_COSTS_CENTERS);
      generalService
        .filter({ only_childs: true })
        .then((response) => {
          this.sub_costs_centers = response.result;
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("Ocurrio un error al obtener los centros de costos.");
        });

      generalService.setSection(Constant.SECTION_MONEY_ACCOUNTS);
      generalService
        .filter({})
        .then((response) => {
          this.money_accounts = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("money_accounts.filter error");
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
