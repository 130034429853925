<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Comprobantes a cobrar</h6>
      <p class="text-body-2">Seleccione los comprobantes a cobrar</p>
      <div class="col-md-12" v-if="isViewModal"></div>
      <v-row class="mt-0" v-if="!isViewModal">
        <div class="col-md-8">
          <v-combobox
            :disabled="isViewModal || selected_client == null"
            v-model="itemVoucher.voucher"
            data-vv-as="Comprobante"
            name="voucher"
            :item-text="voucher_name"
            item-value="id"
            :items="vouchers"
            label="Comprobante*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            :loading="loading_voucher"
            v-on:change="setImporte()"
          ></v-combobox>
          <span class="red--text">{{ errors.first("voucher") }}</span>
        </div>
        <div class="col-md-3">
          <v-text-field
            autocomplete="off"
            name="importe"
            label="Importe*"
            :disabled="isViewModal || itemVoucher.voucher == null"
            placeholder=""
            v-validate="'decimal'"
            type="number"
            v-model="itemVoucher.imp_total"
          />
          <span class="red--text">{{ errors.first("importe") }}</span>
        </div>
      </v-row>
      <v-row class="mt-0">
        <v-col md="12" v-if="!isViewModal">
          <v-btn small color="blue-grey" class="text-white" @click="add()">
            <v-icon big color="green"> add </v-icon>
            <span>Agregar item</span>
          </v-btn>
        </v-col>
        <v-col md="12">
          <v-data-table class="pb-4" :headers="header_vouchers" :items="item.vouchers" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
            <template v-slot:item="props">
              <tr>
                <td>
                  {{ props.item.voucher.voucher_number_label }}
                </td>
                <td>
                  {{ formatUtils.formatPrice(props.item.imp_total) }}
                </td>
                <td>
                  {{ props.item.voucher.id == 0 ? "N/A" : formatUtils.formatPrice(props.item.voucher.imp_total) }}
                </td>
                <td>
                  {{ props.item.voucher.id == 0 ? "N/A" : formatUtils.formatPrice(props.item.voucher.pending_to_charged) }}
                </td>

                <td class="text-center">
                  <v-tooltip bottom v-if="!isViewModal">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="red" @click="remove(props.item)"> indeterminate_check_box </v-icon>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col md="12" v-if="!isAssignVoucher">
          <v-chip class="float-right" outlined color="primary">Total cobrado: {{ formatUtils.formatPrice(total_vouchers) }} </v-chip>
        </v-col>

        <v-col md="12" class="pt-0" v-if="isAssignVoucher">
          <v-chip class="float-right" color="primary">Total Cobranza: {{ formatUtils.formatPrice(item.amount) }} </v-chip>
        </v-col>
        <v-col md="12" class="pt-0" v-if="isAssignVoucher">
          <v-chip class="float-right" outlined color="primary">Total a imputar: {{ formatUtils.formatPrice(total_vouchers) }} </v-chip>
        </v-col>
        <v-col md="12" class="pt-0" v-if="isAssignVoucher">
          <v-chip class="float-right" outlined color="primary">Saldo pendiente: {{ formatUtils.formatPrice(parseFloat(item.amount) - parseFloat(total_vouchers)) }} </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import { vouchersService } from "@/libs/ws/vouchersService";

export default {
  created() {
    //sacamos el validador del padre, para que solo validar al hijo aca.
    //this.$validator = this.validator;
  },
  components: {},
  props: {
    item: Object,
    validator: Object,
    isViewModal: { type: Boolean, default: false },
    isAssignVoucher: { type: Boolean, default: false },
    voucher: { type: Object, default: null },
    open: Boolean,
  },
  data: () => ({
    header_vouchers: [{ text: "Comprobante" }, { text: "Importe a imputar" }, { text: "Total factura" }, { text: "Pendiente de cobro" }, { text: "", value: "", sortable: false, class: "bg-grey" }],
    formatUtils: formatUtils,
    vouchers: [],
    total_vouchers: 0,
    selected_client: null,
    loading_voucher: false,
    defaultItemVoucher: null,
    itemVoucher: {
      id: 0,
      voucher: null,
      imp_total: 0,
    },
  }),
  watch: {
    item: {
      handler: function (newValue) {
        if ((newValue.client != null && this.selected_client == null) || (newValue.client != null && this.selected_client != null && newValue.client.id != this.selected_client.id)) {
          this.selected_client = newValue.client;
          if (!this.isViewModal) this.getVourchers();
        }
      },
      deep: true,
    },
    open: function (newValue) {
      if (this.voucher != null) {
        this.itemVoucher.voucher = this.voucher;
        this.itemVoucher.imp_total = this.voucher.pending_to_charged;
      } else {
        this.itemVoucher = JSON.parse(JSON.stringify(this.defaultItemVoucher));
      }

      this.getVourchers();
      this.$validator.reset();
      if (this.isViewModal && this.item.cobranza_a_cuenta > 0) {
        this.addVoucherACuenta();
      }

      if (!newValue) {
        //CloseModal
        this.vouchers = [];
        this.total_vouchers = 0;
        this.selected_client = null;
      }
    },
    "item.vouchers": {
      handler: function (newValue) {
        this.calcTotalVouchers();
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultItemVoucher = JSON.parse(JSON.stringify(this.itemVoucher));
    if (this.voucher != null) {
      this.itemVoucher.voucher = this.voucher;
      this.itemVoucher.imp_total = this.voucher.pending_to_charged;
    }
    if (this.item != null && this.item.client != null) {
      this.selected_client = this.item.client;
      this.getVourchers();
    }
    if (this.isViewModal && this.item.cobranza_a_cuenta > 0) {
      this.addVoucherACuenta();
    }
    this.calcTotalVouchers();
  },
  methods: {
    addVoucherACuenta() {
      this.itemVoucher.voucher = { id: 0, voucher_number_label: "Cobranza a cuenta", voucher_number: null };
      this.itemVoucher.imp_total = this.item.cobranza_a_cuenta;
      this.item.vouchers.push(this.itemVoucher);
      this.itemVoucher = JSON.parse(JSON.stringify(this.defaultItemVoucher));
    },
    calcTotalVouchers() {
      this.total_vouchers = 0;
      this.item.vouchers.forEach((element) => {
        this.total_vouchers += parseFloat(element.imp_total);
      });
      this.total_vouchers = this.total_vouchers.toFixed(2);
    },
    voucher_name: (item) =>
      item.voucher_number != null
        ? item.cbte_tipo_name + " - " + item.pto_vta.toString().padStart(4, "0") + "-" + item.voucher_number.toString().padStart(8, "0") + " (Pendiente de cobro $" + item.pending_to_charged + ")"
        : item.id == 0
        ? item.voucher_number_label
        : "-",
    setImporte() {
      if (this.itemVoucher.voucher != null && this.itemVoucher.voucher.id != 0) {
        this.itemVoucher.imp_total = this.itemVoucher.voucher.pending_to_charged;
      }
    },
    add() {
      if (this.itemVoucher.imp_total <= 0) {
        this.$toast.error("El importe debe ser mayor a 0");
        return;
      }
      if (this.itemVoucher.imp_total > this.itemVoucher.voucher.pending_to_charged && this.itemVoucher.voucher.id != 0) {
        this.$toast.error("El importe a imputar no puede ser mayor al pendiente de cobro");
        return;
      }
      if (this.isAssignVoucher && parseFloat(this.item.amount).toFixed(2) < (parseFloat(this.total_vouchers) + parseFloat(this.itemVoucher.imp_total)).toFixed(2)) {
        this.$toast.error("El importe a imputar no puede ser mayor al saldo pendiente");
        return;
      }
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          //remuevo el voucher de la lista de vouchers pendientes
          const index_item = this.vouchers.findIndex((objeto) => objeto.id === this.itemVoucher.voucher.id);
          if (index_item > -1) this.vouchers.splice(index_item, 1);

          //busco si el item ya existe en la lista y le sumo el importe a imputar
          const index_item_voucher = this.item.vouchers.findIndex((objeto) => objeto.voucher.id === this.itemVoucher.voucher.id);
          if (index_item_voucher > -1) {
            this.item.vouchers[index_item_voucher].imp_total = parseFloat(this.item.vouchers[index_item_voucher].imp_total) + parseFloat(this.itemVoucher.imp_total);
          } else {
            this.item.vouchers.push(this.itemVoucher);
          }
          this.itemVoucher = JSON.parse(JSON.stringify(this.defaultItemVoucher));
          this.$validator.reset();
        }
      });
    },
    remove(item) {
      const index_item = this.item.vouchers.indexOf(item);
      if (index_item > -1) this.item.vouchers.splice(index_item, 1);

      //agrego el voucher a la lista de vouchers pendientes
      if (item.voucher.id != 0) {
        this.vouchers.push(item.voucher);
      } else {
        this.vouchers.unshift(item.voucher);
      }
    },
    getVourchers() {
      if (this.item.client == null) {
        return;
      }
      this.loading_voucher = true;
      var param = {
        client: this.item.client.id,
      };

      vouchersService
        .getPendingVouchers(param)
        .then((response) => {
          this.vouchers = response.result;

          if (!this.isAssignVoucher) {
            var pago_a_cuenta = { id: 0, voucher_number_label: "Cobranza a cuenta", voucher_number: null };
            this.vouchers.unshift(pago_a_cuenta);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading_voucher = false;
        });
    },
  },
};
</script>
