<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Datos generales</h6>
      <p class="text-body-2 mb-0">Ingrese el emisor de la factura, el receptor y el tipo de comprobante</p>

      <v-row class="mt-0">
        <v-col md="4" class="mt-2">
          <v-combobox
            v-model="invoice.supplier"
            data-vv-as="proveedor"
            name="supplier"
            item-text="name"
            item-value="id"
            :items="suppliers"
            label="Proveedor*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            :loading="loading_combos['suppliers']"
            :disabled="invoice_to_cancel != null || isViewModal || purchase_order || invoice.id > 0"
            @change="getCbtesTipos()"
          ></v-combobox>
          <span class="red--text">{{ errors.first("supplier") }}</span>
        </v-col>

        <v-col md="4" class="mt-2">
          <v-combobox
            v-model="invoice.receiver"
            data-vv-as="receptor"
            name="receiver"
            item-text="name"
            item-value="id"
            :items="receivers"
            label="Receptor*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            :loading="loading_combos['receivers']"
            :disabled="invoice_to_cancel != null || isViewModal || purchase_order || invoice.id > 0"
          ></v-combobox>
          <span class="red--text">{{ errors.first("receiver") }}</span>
        </v-col>
        <v-col md="4">
          <v-combobox
            v-model="invoice.cbte_tipo"
            data-vv-as="tipo de comprobante"
            name="cbte_tipo"
            item-text="Desc"
            item-value="Id"
            :items="cbte_tipos"
            label="Tipo de comprobante*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            class="overflow-auto"
            :loading="loading_combos['cbte_tipos_invoice']"
            :disabled="cbte_tipos.length == 0 || isViewModal || invoice_to_cancel != null || invoice.id > 0"
            filled
          ></v-combobox>
          <span class="red--text">{{ errors.first("cbte_tipo") }}</span>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col md="3">
          <v-text-field
            autocomplete="off"
            data-vv-as="nro de comprobante"
            label="Nº de Comprobante"
            name="voucher_number"
            v-model="invoice.voucher_number"
            :disabled="isViewModal"
            @blur="validateAndAutocomplete"
            v-validate="'max:20'"
          >
            ></v-text-field
          >
          <span class="red--text">{{ errors.first("voucher_number") }}</span>
        </v-col>

        <v-col md="3">
          <v-combobox
            :loading="loading_combos['monedas']"
            :disabled="invoice_to_cancel != null || monedas.length == 0 || isViewModal || invoice.id > 0 || purchase_order"
            v-model="invoice.moneda"
            data-vv-as="moneda"
            name="moneda"
            item-text="Desc"
            item-value="Id"
            :items="monedas"
            label="Moneda*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
          ></v-combobox>
          <span class="red--text">{{ errors.first("moneda") }}</span>
        </v-col>

        <v-col md="3" v-if="invoice.moneda != null && invoice.moneda.Id !== 'PES'">
          <v-text-field
            autocomplete="off"
            data-vv-as="cotizacion de la moneda"
            label="Cotización de la moneda*"
            name="mon_cotiz"
            v-validate="'required|decimal|required'"
            v-model="invoice.mon_cotiz"
            :disabled="invoice_to_cancel != null || isViewModal || invoice.id > 0"
          >
          </v-text-field>
          <span class="red--text">{{ errors.first("mon_cotiz") }}</span>
        </v-col>
        <v-col md="3">
          <v-text-field
            v-if="invoice.purchase_order != null"
            autocomplete="off"
            data-vv-as="orden de compra"
            label="Orden de Compra"
            name="purchase_order"
            v-model="invoice.purchase_order.purchase_order_number"
            :disabled="true"
          >
          </v-text-field>
          <span class="red--text">{{ errors.first("purchase_order") }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { emmiterService } from "@/libs/ws/emmiterService";
import { vouchersService } from "@/libs/ws/vouchersService";

import Constant from "@/constants/sections";

import { spentService } from "@/libs/ws/spentService";
import { generalService } from "@/libs/ws/generalService";
import CbeTipos from "../../../constants/cbe_tipos";

export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  props: {
    open: Boolean,
    invoice: Object,
    invoice_to_cancel: Object,
    loading_combos: Object,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
    purchase_order: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    suppliers: [],
    receivers: [],
    cbte_tipos: [],
    ptosvtas: [],
    conceptos: [],
    monedas: [],
  }),
  watch: {
    open: function (val) {
      if (val) {
        this.errors.clear();
        this.$validator.reset();
        this.loadComboData();
      }
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    validateAndAutocomplete() {
      const voucherNumber = this.invoice.voucher_number;

      if (voucherNumber.length > 20) {
        this.invoice.voucher_number = voucherNumber.slice(0, 20);
      }

      const afipRegex = /^\d{1,5}-\d{1,8}$/;
      if (afipRegex.test(voucherNumber)) {
        const parts = voucherNumber.split("-");
        if (parts.length === 2) {
          const pointOfSale = parts[0].padStart(5, "0");
          const invoiceNumber = parts[1].padStart(8, "0");
          this.invoice.voucher_number = `${pointOfSale}-${invoiceNumber}`;
        }
      }
    },
    receiver_name: (item) => (item == null ? "" : item.name + " (" + item.client.name + ")"),
    loadComboData() {
      this.loading_combos["suppliers"] = true;
      generalService.setSection(Constant.SECTION_SUPPLIERS);
      generalService
        .filter()
        .then((response) => {
          this.suppliers = response.result;
          this.getCbtesTipos();
        })
        .finally(() => {
          this.loading_combos["suppliers"] = false;
        });

      this.loading_combos["receivers"] = true;
      emmiterService
        .filter({ enabled: true })
        .then((response) => {
          this.receivers = response.result;
          if (response.result.length == 1) {
            this.invoice.receiver = response.result[0];
          }
        })
        .finally(() => {
          this.loading_combos["receivers"] = false;
        });

      this.monedas = spentService.getMonedas();      
      if(!this.invoice.moneda){
        this.invoice.moneda = this.monedas[0];
      }
      if (this.invoice_to_cancel != null) this.selectComboId(this.monedas, this.invoice_to_cancel.moneda, "Id");
    },

    getCbtesTipos() {
      if (this.invoice.supplier == null) {
        return;
      }
      this.loading_combos["cbte_tipos"] = true;
      const iva_condition_id = this.invoice.supplier.iva_condition != null ? this.invoice.supplier.iva_condition.id : null;
      spentService.cbteTiposInvoice(iva_condition_id, "all").then((response) => {
        if (response.status) {
          this.cbte_tipos = response.result;

          if (iva_condition_id === CbeTipos.CBE_TIPO_RESP_INSCRIPTO) {
            this.invoice.cbte_tipo = response.result[1];
          } else if (iva_condition_id === CbeTipos.CBE_TIPO_MONOTRIBUTISTA) {
            this.invoice.cbte_tipo = response.result[3];
          } else {
            this.invoice.cbte_tipo = response.result[0];
          }

          if (this.invoice_to_cancel != null) this.invoice.cbte_tipo = this.selectComboId(this.cbte_tipos, vouchersService.getCancelIdForCbteTipo(this.invoice_to_cancel.cbte_tipo), "Id");

          if (this.purchase_order) {
            //si se genera desde una orden de compra, eliminamos los comprobantes que son de tipo NC
            this.cbte_tipos = this.cbte_tipos.filter((cbte_tipo) => vouchersService.NoteCreditList().indexOf(cbte_tipo.Id) === -1);
          }

          this.loading_combos["cbte_tipos"] = false;
        }
      });
    },
    selectComboId(combo, id, property_id = "id") {
      if (combo != null && combo.length > 0) {
        for (let i = 0; i < combo.length; i++) {
          if (combo[i][property_id] == id) {
            return combo[i];
          }
        }
      }
      return null;
    },
  },
};
</script>
