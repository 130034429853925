<template>
  <div>
    <Title
      :pages="[
        { icon: 'users', path: '/suppliers', page: 'PROVEEDORES' },
        { icon: 'circle', page: page_title.toUpperCase() + ': ' + getName() },
      ]"
    ></Title>

    <v-container fluid>
      <DialogLoading :dialog="loading_view_payment" text="Cargando..." />

      <ModalSpentPayment
        formBtn=""
        :dialog="dialog_view_payment"
        :parent="parent"
        :spentPayment="mainItemPayment"
        @closeDialog="updateDialogPayment"
        @updateItem="
          (newItem) => {
            mainItemPayment = newItem;
          }
        "
      />

      <DialogLoading :dialog="loading_view_comprobante" text="Cargando..." />

      <DialogGenerarSpent :invoice_to_generate="mainItemComprobante" :open="dialog_view_comprobante" @cancel="updateDialogComprobante" @closeDialog="updateDialogComprobante" formBtn="" />

      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-4 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-4 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-4 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="blue-grey" dark :loading="dialog_export" @click="exportFile()" class="mr-2"> Exportar <font-awesome-icon icon="download" class="ml-2" /></v-btn>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <h5 v-if="list_access" style="padding: 10px; margin-top: 0px; background: #222d32; color: #fff; font-weight: bold">Saldo acumulado: ${{ new Intl.NumberFormat("en-EN").format(total) }}</h5>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.cbte_fch_esp }}</td>
              <td>{{ props.item.data_type }}</td>
              <td>
                <span class="redirect-modal" @click="handleModal(props.item.data_type, props.item.id)">{{ props.item.voucher_number_label }}</span>
              </td>
              <td class="text-right">{{ props.item.imp_total_spent != "" ? formatUtils.formatPrice(props.item.imp_total_spent) : "-" }}</td>
              <td class="text-right">{{ props.item.imp_total_paid != "" ? formatUtils.formatPrice(props.item.imp_total_paid) : "-" }}</td>
              <td class="text-right" v-if="props.item.saldo >= 0">{{ formatUtils.formatPrice(props.item.saldo) }}</td>
              <td class="text-right" v-else>{{ "(" + formatUtils.formatPrice(props.item.saldo) + ")" }}</td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { formatUtils } from "@/libs/utils/formatUtils";
import { balancesService } from "@/libs/ws/balancesService";
import { generalService } from "@/libs/ws/generalService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import { paymentSpentService } from "@/libs/ws/paymentSpentService";
import { spentService } from "@/libs/ws/spentService";
import DialogLoading from "@/components/DialogLoading.vue";
import ModalSpentPayment from "@/views/Spents/ModalSpentPayment.vue";
import DialogGenerarSpent from "@/views/Spents/DialogGenerarSpent.vue";

export default {
  components: { Title, DialogLoading, ModalSpentPayment, DialogGenerarSpent },
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      parent: this,
      formatUtils: formatUtils,
      service: balancesService,
      page_title: "Cuenta Corriente",
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Tipo", align: "left", sortable: true, value: "data_type" },
        { text: "Nº Comprobante", align: "left", sortable: true, value: "voucher_number_label" },
        { text: "Debe", align: "right", sortable: true, value: "imp_total_spent" },
        { text: "Haber", align: "right", sortable: true, value: "imp_total_paid" },
        { text: "Saldo", align: "right", sortable: true, value: "saldo" },
      ],
      list: [],
      desde_selected_open: false,
      hasta_selected_open: false,
      desde_selected: new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      total: 0,
      supplierId: 0,
      supplier: null,
      dialog_view_comprobante: false,
      dialog_view_payment: false,
      loading_view_payment: false,
      loading_view_comprobante: false,
      mainItemPayment: {
        id: 0,
        supplier: null,
        payment_method: null,
        dolar_cotiz: null,
        fch_payment_efective: null,
        spent_payment_methods: [],
        spents: [],
        tributes: [],
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
      },
      mainItemComprobante: {
        id: null,
        voucher_number: null,
        cost_center: null,
        dolar_cotiz: null,
        supplier: null,
        receiver: null,
        cbte_tipo: null,
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        cbte_fch_serv_desde: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        cbte_fch_serv_hasta: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        fch_vto_pago: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        concepto: null,
        moneda: null,
        mon_cotiz: 1,
        items_custom: [],
        tributes: [],
        cbte_asoc: null,
      },
    };
  },
  mounted() {
    this.supplierId = this.$route.params.supplier_id;

    this.service.setSection(Constant.SECTION_SUPPLIERS_BALANCES);
    this.loadList(this.service);
  },
  watch: {
    supplierId(val) {
      this.filter();
    },
  },
  methods: {
    updateDialogPayment() {
      this.dialog_view_payment = false;
    },
    updateDialogComprobante() {
      this.dialog_view_comprobante = false;
    },
    handleModal(data_type, id) {
      if (data_type == "Pago") {
        this.loading_view_payment = true;

        paymentSpentService
          .get(parseInt(id))
          .then((response) => {
            this.mainItemPayment = response.result;
            this.loading_view_payment = false;
            this.dialog_view_payment = true;
          })
          .catch((e) => {
            console.log("error service charge get load.error");
            console.log(e);
            this.loading_view_payment = false;
          })
          .finally(() => {
            this.loading_view_payment = false;
          });
      } else if (data_type == "Gasto") {
        this.loading_view_comprobante = true;
        spentService
          .get(parseInt(id))
          .then((response) => {
            this.mainItemComprobante = response.result;
            this.loading_view_comprobante = false;
            this.dialog_view_comprobante = true;
            console.log("mainItemComprobante", this.mainItemComprobante);
            console.log("response", response.result);
          })
          .catch((e) => {
            console.log("error service voucher get load.error");
            console.log(e);
            this.loading_view_comprobante = false;
          })
          .finally(() => {
            this.loading_view_comprobante = false;
          });
      }
    },
    getName() {
      return this.supplier != null ? this.supplier.name : "-";
    },
    getFilterParams() {
      var param = {
        supplier_id: this.supplierId,
        desde: this.desde_selected,
        hasta: this.hasta_selected,
      };
      return param;
    },
    exportFile() {
      var param = this.getFilterParams();
      param["export"] = true;
      this.exportExcel(Constant.SECTION_SUPPLIERS_BALANCES + "/list", param, "Cuenta-Corriente-" + this.supplier.name + ".xlsx");
    },
    filter() {
      if (this.supplierId > 0) {
        generalService.setSection(Constant.SECTION_SUPPLIERS);
        generalService
          .get(this.supplierId)
          .then((response) => {
            if (response.status === true) {
              this.supplier = response.result;
              this.total = this.supplier.balance;
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.$toast.error(response.message, "Error");
              } else {
                this.$toast.error(response.msg, "Error");
              }
            }
          })
          .catch((e) => {
            console.log("generalService.get error");
            console.log(e);
            this.$toast.error("Error exception", "Error");
          });

        this.loadList(this.service, this.getFilterParams());
      }
    },
  },
};
</script>
